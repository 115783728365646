import React, { useEffect, useState } from 'react';
import Modal from '../../Utilities/Modal';
import Uploader from '../photos/uploadPhotos';
import { Card, CardContent, Typography } from '@mui/material';
import moment from 'moment';
import { createActionData } from '../extra-components/actiondata';
import User from '@paljs/ui/User';
import { getInfoOfSelectedUser } from '../../actions/matrimonialAction';
import { nodeDetails } from '../../actions/nodeDetailsAction';
import { updateUserDetails } from '../../actions/userAction';
import { toast } from 'react-toastify';
import MukhiyasList from '../samaj_mitra/mukhiyasList';

function UploadMukhiyaDetails({ selectedNode = {}, onClose, setMukhiyaListImg }) {
  const [photos, setPhotos] = useState('');
  // const [NodeDetails, setNodeDetails] = useState('');
  // function removeIdFromObject(data) {
  //   const { _id, ...filteredData } = data;
  //   return filteredData;
  // }

  // const getDetails = async () => {
  //   try {
  //     const nodeId = selectedNode?.uid;
  //     const response = await getInfoOfSelectedUser(nodeId);
  //     const filteredProfile = removeIdFromObject(response?.basicProfile);
  //     setNodeDetails(filteredProfile);
  //   } catch (e) {
  //     console.log('error', e);
  //   }
  // };
  // useEffect(() => {
  //   getDetails();
  // }, []);
  const updateData = async (url) => {
    setPhotos(url);
    const payload = {
      // ...NodeDetails,
      graph_node_id: selectedNode?.uid,
      mukhiya_list_img: url,
    };
    try {
      const response = await updateUserDetails(payload);
      console.log('response from updatephoto', response);
      toast.success('Mukhiya list image upload successfully!');
      onClose();
      setMukhiyaListImg(url);
    } catch (error) {
      toast.success('Filed to upload mukhiya list image');

      console.log('error', error);
    }
  };
  console.log('uploaded photots', photos);

  return (
    <Modal toggle={onClose}>
      <Card
        style={{
          width: '100%',
          margin: '20px auto',
          maxWidth: '600px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <CardContent>
          <Typography variant="h6" component="div" style={{ textAlign: 'center', marginBottom: '20px' }}>
            मुखियाओं की लिस्ट अपलोड करें{' '}
          </Typography>
          <Uploader samajMitraNodeId={selectedNode?.uid} onUploadSuccess={(url) => updateData(url)} />
        </CardContent>
      </Card>
    </Modal>
  );
}

export default UploadMukhiyaDetails;
